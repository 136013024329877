import React from "react";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./theme.css";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="page home">
      <h1>Hi there!</h1>
      <p>
        I'm Brooke Kindleman, a final-year software engineering student at the
        University of Calgary. I'm passionate about creating accessible software
        and using my development skills to help those around me. While my
        favourite language is <em>C++</em>, I'm comfortable in a multitude of
        languages including <em>C</em>, <em>Java</em>, <em>Javascript</em>, and{" "}
        <em>React</em>.
      </p>
      <p>
        My main interests include the development of graphics and game engines,
        as well as the use of computer graphics as a medium for art. I have
        experience in using <em>OpenGL</em>, <em>Microsoft Visual Studio</em>,{" "}
        <em>Nvidia's PhysX engine</em>, and <em>Blender</em> for creating video
        games.
      </p>
      <p>
        Outside of my work in class, I frequently create 3D models for use in
        art and game development, and am always learning more about how to
        create faster and more appealing products.
      </p>
      <p>
        Most of my work is done on <em>Ubuntu Linux</em>, but I'm comfortable
        with using <em>Windows 8/10/11</em>, and am becoming familiar with{" "}
        <em>MacOS</em>.
      </p>
      <p>
        Feel free to reach out to me on{" "}
        <a href="https://github.com/brooke-k">GitHub</a> or{" "}
        <a href="https://www.linkedin.com/in/brooke-kindleman/">LinkedIn</a> -
        I'd love to talk!
      </p>
      <div className="link-lists">
        <div>
          <h2>Connect with Me</h2>
          <a href="https://www.linkedin.com/in/brooke-kindleman/">
            <img
              src="https://img.shields.io/badge/linkedin-%230077B5.svg?style=for-the-badge&logo=linkedin&logoColor=white"
              alt="LinkedIn logo"
            ></img>
          </a>
          <a href="https://github.com/brooke-k">
            <img
              src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white"
              alt="GitHub logo"
            ></img>
          </a>
        </div>
        <div>
          <h2>Portfolio Projects</h2>
          <div className="spotme">
            <a href="https://spotme.brooke-kindleman.com">
              SpotMe - Spotify Data Visualiser
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
