import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from './ErrorPage';
import Home from './Home';
import { Provider } from 'react-redux';
import store from './store';
import "./theme.css";

const appRouter = createHashRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Provider store={store}><Home /></Provider>
  }

])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter} />
    <div className="footer-runner">
      <p>&#169; 2023 Brooke Kindleman</p>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
